import "./App.scss";
import "./Style.scss";

import AboutSection from "./sections/AboutSection";
import BackgroundCanvas from "./components/BackgroundCanvas";
import Footer from "./sections/Footer";
import LandingSection from "./sections/LandingSection";
import Navbar from "./components/Navbar";
import ProjectSection from "./sections/ProjectSection";
import React from "react";
import SideNav from "./components/SideNav";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.sections = [
      {
        name: "Home",
        jsx: <LandingSection />,
      },
      {
        name: "About Me",
        jsx: <AboutSection />,
      },
      {
        name: "Projects",
        jsx: <ProjectSection />,
      },
    ];

    this.sections = this.sections.map((s, i) => {
      s.id = "section" + i;
      s.jsx = React.cloneElement(s.jsx, { id: s.id });
      return s;
    });

    this.numSections = this.sections.length;

    this.state = {
      activeSection: this.sections[0].id,
      active: true,
    };

    window.onscroll = this.handleScroll;
  }

  handleScroll = () => {
    // set home
    if (window.scrollY === 0 && !this.state.home) {
      this.setState({ home: true });
      this.handleMouseMove();
    }
    if (window.scrollY !== 0 && this.state.home) this.setState({ home: false });

    // find active section
    for (let i = this.numSections - 2; i >= 0; i--) {
      let element = document.getElementById(this.sections[i + 1].id);
      if (element.getBoundingClientRect().top - window.innerHeight / 5 < 0) {
        this.setState({ activeSection: this.sections[i + 1].id });
        return;
      }
    }
    this.setState({ activeSection: this.sections[0].id });
  };

  handleScrollTo = (id) => {
    var elmnt = document.getElementById(id);
    elmnt.scrollIntoView();
  };

  handleMouseMove = () => {
    if (this.timeout) clearTimeout(this.timeout);

    if (!this.state.active) this.setState({ active: true });

    this.timeout = setTimeout(() => {
      this.setState({ active: false });
    }, 5000);
  };

  componentDidMount = () => {
    this.handleScroll();
    this.handleMouseMove();
  };

  render() {
    return (
      <div className="App" onMouseMove={this.handleMouseMove}>
        <BackgroundCanvas />
        <SideNav
          sections={this.sections}
          scrollToFunc={this.handleScrollTo}
          activeSection={this.state.activeSection}
          home={this.state.home}
          active={this.state.active}
        />
        <Navbar home={this.state.home} />
        {this.sections.map((s) => s.jsx)}
        <Footer />
      </div>
    );
  }
}

export default App;
