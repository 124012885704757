import "./ProjectSection.scss";

import Project from "../components/Project";
import React from "react";
import Section from "./Section";
import projectData from "../data/projects.json";
import projectImages from "../data/projectImages";

class ProjectSection extends React.Component {
  render() {
    return (
      <Section title="Projects" id={this.props.id}>
        <div className="project-container">
          {projectData.map((p, i) => {
            return <Project project={p} img={projectImages[p.name]} key={i} />;
          })}
        </div>
      </Section>
    );
  }
}

export default ProjectSection;
