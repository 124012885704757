import "./AboutSection.scss";

import React from "react";
import Section from "./Section";

class AboutSection extends React.Component {
  render() {
    return (
      <Section title="About Me" section={"about-section"} id={this.props.id}>
        <div className="content-wrapper">
          <p>
            Hello! I'm a fourth year computer science student at the University of Waterloo. I most
            recently built Pinecone's Rust SDK and worked with embedding models at Microsoft.
            My focus is currently in AI/ML, and I thrive on tackling interesting challenges
            and learning new things.
          </p>
          <p>Here are some languages and technologies that I use:</p>
          <ul className="tech-stack">
            <li>Python</li>
            <li>Rust</li>
            <li>C++</li>
            <li>JavaScript/Typescript</li>
            <li>React, Express</li>
          </ul>
          <p>
            Outside of academics, I regularly play badminton and participate in
            chamber ensembles as a cellist. I love to marvel at cool website and
            product design from time to time, as well as dabble in creative
            hobbies like transcribing and recording music. Check out my{" "}
            <a href="https://musescore.com/user/30196155" target="_blank" rel="noopener noreferrer">
              MuseScore
            </a>
            {" "}and{" "}
            <a href="https://www.bandlab.com/aezara" target="_blank" rel="noopener noreferrer">
              BandLab
            </a>!
          </p>
          <p>
            I also love to attend hackathons! I placed as a finalist with my
            team at Hack the North 2021, Canada's Biggest Hackathon. Visit my{" "}
            <a href="https://devpost.com/emily-emily" target="_blank" rel="noopener noreferrer">
              Devpost
            </a>{" "}
            for some of my latest hacks.
          </p>
        </div>
      </Section>
    );
  }
}

export default AboutSection;
