import "./LandingSection.scss";

import React from "react";
import Section from "./Section";

class LandingSection extends React.Component {
  render() {
    return (
      <Section section="landing-section" id={this.props.id}>
        <h1 className="name">Emily Yu</h1>
      </Section>
    );
  }
}

export default LandingSection;
