import "./SideNav.scss";

import React from "react";

class SideNav extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.state = { hidden: false };
    window.addEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    if (window.innerWidth < 850 && !this.state.hidden) {
      this.setState({ hidden: true });
    } else if (window.innerWidth >= 850 && this.state.hidden) {
      this.setState({ hidden: false });
    }
  };

  componentDidMount = () => {
    this.handleWindowResize();
  };

  render() {
    let classname = "";
    if (this.props.home) classname += " home";
    if (this.props.active) classname += " active";

    return (
      <div
        ref={this.ref}
        className={"side-nav" + classname}
        style={{ display: this.state.hidden ? "none" : "inline-block" }}
      >
        {this.props.sections.map((s, i) => {
          return (
            <li
              className={this.props.activeSection === s.id ? "active" : ""}
              key={i}
            >
              <span
                className="dot-wrapper"
                onClick={() => {
                  this.props.scrollToFunc(s.id);
                }}
              >
                <span className="item-dot" />
              </span>
              <h3
                onClick={() => {
                  this.props.scrollToFunc(s.id);
                }}
              >
                {s.name}
              </h3>
            </li>
          );
        })}
      </div>
    );
  }
}

export default SideNav;
