import gameoflife from "../assets/game-of-life.png";
import neuralnet from "../assets/neuralnet.png";
import polycursor from "../assets/poly-cursor.png";
import talko from "../assets/talko.png";
import voicenoted from "../assets/voicenoted.png";
import rustsdk from "../assets/rust-sdk.png";

const projectImages = {
  "Pinecone Rust SDK": rustsdk,
  "Neural Network in C++": neuralnet,
  talko: talko,
  "voicenoted.": voicenoted,
  "poly-cursor": polycursor,
  "Game of Life": gameoflife,
};

export default projectImages;
