import "./Project.scss";

import { FiGithub } from "react-icons/fi";
import { HiOutlineCursorClick } from "react-icons/hi";
import React from "react";
import { SiDevpost } from "react-icons/si";

class Project extends React.Component {
  render() {
    return (
      <div className="project">
        <div className="img-description-box">
          {this.props.img && <img src={this.props.img} alt={"Project: " + this.props.project.description} />}
          {this.props.project.description && (
            <div className="description">{this.props.project.description}</div>
          )}
        </div>
        <div className="text">
          <div className="name">{this.props.project.name}</div>
          <div className="tech">{this.props.project.tech}</div>
          <div className="icon-wrapper">
            {this.props.project.github && (
              <a
                className="icon-link"
                href={this.props.project.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiGithub />
              </a>
            )}
            {this.props.project.devpost && (
              <a
                className="icon-link"
                href={this.props.project.devpost}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiDevpost />
              </a>
            )}
            {this.props.project.demo && (
              <a
                className="icon-link"
                href={this.props.project.demo}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HiOutlineCursorClick />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Project;
