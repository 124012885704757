import "./Section.scss";

import React from "react";

class Section extends React.Component {
  render() {
    return (
      <div
        className={"section" + (" " + this.props.section || "")}
        id={this.props.id}
      >
        {this.props.title && (
          <div className="content-wrapper">
            <h2 className="section-title">{this.props.title}</h2>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default Section;
