import "./Footer.scss";

import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <p>Emily Yu · 2024</p>
      </div>
    );
  }
}

export default Footer;
